


































import { ssmPermissionService } from "@/shared/services/PermissionService";
import { UtilsString } from "@/utils/utils-string";
import { Component, PropSync, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Xbtn extends Vue {
  @PropSync("color", { default: "primary" }) Xbtn_color!: string;
  @PropSync("icon", { default: false }) Xbtn_icon!: boolean;
  @PropSync("text", { default: false }) Xbtn_text!: boolean;
  @PropSync("small", { default: false }) Xbtn_small!: boolean;
  @PropSync("permiso", { default: UtilsString.Empty }) Xbtn_permiso!: string;
  @PropSync("permisos", { default: [] }) Xbtn_permisos!: string[];

  get visible() {
    if (this.Xbtn_permisos.length === 0) {
      if (this.Xbtn_permiso === UtilsString.Empty) {
        return true;
      }
      return ssmPermissionService.hasPermission(this.Xbtn_permiso);
    } else {
      for (let i = 0; i < this.Xbtn_permisos.length; i++) {
        var elemento = ssmPermissionService.hasPermission(
          this.Xbtn_permisos[i]
        );
        if (elemento) {
          return true;
        }
      }
    }
    return false;
  }

  public refrescar() {
    this.$forceUpdate();
  }
}
